//-- HELPERS -----------------------------------------------------//

const findProductOptionById = (options, id) => {
  return options.find(option => option.option_id == id);
};

export const findProductOptionBySku = (options, sku) => {
  return options.find(option => option.sku === sku);
};

const findProductOption = (options, skuOrId) => {
  return findProductOptionById(options, skuOrId) || findProductOptionBySku(options, String(skuOrId));
};

const findProductOptionValueById = (option, id) => {
  return option.values.find(value => value.id === id);
};

const findProductOptionValueBySku = (option, sku) => {
  if (!option.values) return null;
  return option.values.find(value => value.sku === sku);
};

const findProductOptionValueBySkuOrId = (option, skuOrId) => {
  return findProductOptionValueById(option, skuOrId) || findProductOptionValueBySku(option, String(skuOrId));
};

//-- SELECTORS -----------------------------------------------------//

const getValues = (state) => {
  return Object.keys(state.valuesByPartId).map(partId => state.valuesByPartId[partId]);
}

const getTotalPrice = (state) => {
  if (!state.product) return 0;
  const basePrice = state.product.unit_price;
  const totalPrice = getValues(state).reduce((t, {price}) => t + price, basePrice);
  return totalPrice;
}

const getIncompleteOptions = (state) => {
  const incompleteOptions = state.options.filter(o => {
    const isRequired = o.validation_rules.indexOf('required') !== -1;
    return isRequired && !state.valuesByPartId.hasOwnProperty(o.option_id);
  });

  return incompleteOptions;
}

const getIsBagComplete = (state) => {
  return getIncompleteOptions(state).length === 0;
}

//-- STORE HELPERS -------------------------------------------------//

const setProduct = (state, {product}) => {
  // Loop through all groups to get each group's ootions
  const options = [...product.options];

  // Create a hash of all options keyed by their IDs.
  const optionsById = product.options.reduce((obj, option) => {
    obj[option.option_id] = {...option};
    return obj;
  }, {});

  const newState = {...initialState, optionsById, product, options, productId: product.sku};

  return newState;
}

const setCurStep = (state, {step}) => {
  return {
    ...state,
    curStep: !state.optionsById[step] ? null : step
  };
}

const setCurStepByPartId = (state, {partId}) => {
  const index = state.options.findIndex(option => option.option_id === partId);
  return setCurStep(state, {step: index + 1});
}

/**
 * Stores the provided value for the provided option.
 */
const setProductOptionValue = (state, {optionId, value}) => {
  const newState = {...state};
  const option = findProductOption(newState.options, optionId);

  if (!option) {
    return newState;
  }

  // Setting the value to null for
  if (value === null) {
    delete newState.valuesByPartId[option.option_id];
    return newState;
  }

  // TODO: Validate value against option's "validation_rules"
  // TODO: Figure out how to handle values of different types.
  // TODO: Move away from SKU and use the material's ID instead.
  if (option.sku === 'mo') {
    return setProductMonogram(state, {optionId: option.option_id, value});
  } else if (option.sku === 'ms') {
    return setProductMonogramStyle(state, {optionId: option.option_id, value})
  } else if (option.sku === 'mnmplt') {
    return setProductEngraving(state, {optionId: option.option_id, value})
  } else {
    return setOption(state, {option, value});
  }
}

const setProductEngraving = (state, {optionId, value}) => {
  const newState = {...state};
  const [lineOne, lineTwo] = value;

  newState.valuesByPartId[optionId] = {
    name:  lineOne || lineTwo,
    value: JSON.stringify(value),
    image: null,
    price: 0,
  };

  return newState;
}

const setProductMonogram = (state, {optionId, value}) => {
  const newState = {...state};

  newState.valuesByPartId[optionId] = {
    name:  value,
    value: value,
    image: null,
    price: 1000
  };

  return newState;
}

const setProductMonogramStyle = (state, {optionId, value}) => {
  const newState = {...state};

  newState.valuesByPartId[optionId] = {
    name: value,
    value: value,
    image: null,
    price: 0,
  };

  return newState;
}

const setOption = (state, {option, value}) => {
  const newState = {...state};

  try {
    const { title:name, sku, image, price } = findProductOptionValueBySkuOrId(option, value);
    newState.valuesByPartId[option.option_id] = {
      name,
      value: sku,
      image,
      price
    };
  } catch(error) {
    return newState;
  }

  return newState;
}

//-- INITIAL STATE -------------------------------------------------//

export const initialState = {
  product: null,
  productId: null,
  curStep: null,
  totalPrice: 0,
  options: [],
  optionsById: {},
  optionGroups: [],
  valuesByPartId: {},
};

//-- REDUCER -------------------------------------------------------//

export const reducer = (state, action) => {
  // Helper method for computing additional properties during allstate
  // changes.
  const addFields = (state) => {
    const totalPrice = getTotalPrice(state);
    const isBagComplete = getIsBagComplete(state);
    return {...state, isBagComplete, totalPrice};
  }

  switch (action.type) {
    case 'SET_PRODUCT':
      return addFields(setProduct(state, action));

    case 'SET_OPTION_VALUE':
      return addFields(setProductOptionValue(state, action));

    case 'GOTO_STEP':
      return addFields(setCurStep(state, action));

    case 'GOTO_PART':
      return addFields(setCurStepByPartId(state, action));
  }
};
