
import React, { useCallback, useContext } from 'react';
import { AppContext } from '../../reducers/AppContext';
import { useEmitter } from '../../utils/events';
import { LuxeBadge } from './LuxeBadge';

const getBorderStyles = (isActive) => {
  const borderTransition = 'transition-all duration-300 ease-linear';
  const borderOpacity = isActive ? 'opacity-100' : 'opacity-0';

  // Hover & active styles
  return {
    className: `absolute z-20 top-0 left-0 w-full h-full border ${borderTransition} ${borderOpacity} hover:opacity-100`,
    style: {
      borderWidth: '5px',
      borderColor: isActive ? '#B90000' : '#E0E0E0',
    }
  }
}

export const MaterialMenuItem = ({material, isActive, option}) => {
  const emitter = useEmitter();
  const { actions } = useContext(AppContext);
  const showLuxeBadge = material.price > 0;
  const border = getBorderStyles(isActive)

  // Helpers
  const previewMaterial = (material) => {
    emitter.emit('material_menu__preview-material', material)
  }


  // Mouse event handlers
  const onMouseOver = useCallback(() => previewMaterial(material));
  const onMouseOut = useCallback(() => previewMaterial(null));
  const onClick = useCallback(() => {
    emitter.emit('change-material', {optionSku: option.sku, materialSku: material.sku});
    actions.setOptionValue(option.option_id, material.id);
    previewMaterial(null);
  });

  return (
    <div>
      <div className="relative cursor-pointer" style={{backgroundColor: '#ccc'}} {...{onClick, onMouseOver, onMouseOut}}>
        {showLuxeBadge && (
          <div className="absolute z-10 w-full h-full flex flex-col items-center justify-center">
            <LuxeBadge width={10} height={21} />
          </div>
        )}
        <div {...border}></div>
        <div className="relative" style={{paddingBottom: '100%'}}>
          <img className="absolute top-0 left-0 w-full h-auto" src={material.image} alt={material.title} />
        </div>
      </div>
      <div className="mt-2 text-xs text-center">{material.title}</div>
    </div>
  );
}
