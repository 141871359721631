import React, { useCallback } from 'react';
import {useContext, useEffect, useState} from 'react';
import { AppContext } from '../../reducers/AppContext';
import { getElement as getLoadedProduct } from '../../utils/productViewLoader';
import { useRef } from 'react';

const changeMaterials = (query) => {
  const parts = query.split('&').map(pair => pair.split('='));
  const groups = parts.map(pair => {
    return {componentId: pair[0], materialSku: pair[1]};
  });

  window.designer.changeMaterials(groups);
}

export const ProductPreview = () => {
  // const { previewQuery, state } = useContext(AppContext);
  const previewContainer = useRef();

  // useEffect(() => {
  //   changeMaterials(previewQuery);
  // }, [previewQuery]);

  const previewMouseDown = useCallback(() => {
    previewContainer.current.style.cursor = 'grabbing';
  }, [previewContainer]);

  const previewMouseUp = useCallback(() => {
    previewContainer.current.style.cursor = 'grab';
  }, [previewContainer]);

  useEffect(() => {
    const element = getLoadedProduct();
    previewContainer.current.appendChild(element);
  }, [])

  return (
    <div ref={previewContainer}
      key={'previewContainer'}
      style={{cursor: 'grab'}}
      onMouseDown={previewMouseDown}
      onMouseUp={previewMouseUp}
    />
  );
};
