import {useEffect, useState} from 'react';

const makeU3dApiCall = (method, request) => {
    return new Promise((resolve, reject) => {
        method(request, (error, result) => {
            if (error) reject(new Error(`Unable to perform action. ${error.errorMessage}`));
            resolve(result);
        });
    });
}

async function allSynchronously(resolvables) {
    const results = [];
    
    for (const resolvable of resolvables) {
        results.push(await resolvable());
    }
    
    return results;
}

const handleActions = async (actions) => {

    const performAction = (action) => {
        const [type, value] = action;
        
        switch(type) {
            case 'show_parts':
                return makeU3dApiCall(window.Unlimited3D.showParts, {partObjects: [{parts: value.split(',')}]});

            case 'hide_parts':
                return makeU3dApiCall(window.Unlimited3D.hideParts, {parts:value.split(',')});

            case 'set_active_camera':
                return makeU3dApiCall(window.Unlimited3D.activateCamera, {name:value});

            case 'play_transition':
                return makeU3dApiCall(window.Unlimited3D.activateTransition, {transition: value});

            default:
                return Promise.resolve();
        }
    };

    return allSynchronously(
        actions.map(action => {
            return () => {
                return new Promise(resolve => {
                    // Perform the action
                    performAction(action).then(() => {
                        // Wait between actions
                        setTimeout(() => resolve(), 100);
                    });
                });
            };
        })
    );
}

export const ActionWrapper = ({option, children}) => {

    const [isReady, setIsReady] = useState(false);

    useEffect(() => {
        handleActions(option.actions.onOptionOpened ?? []).then(() => setIsReady(true));

        return () => handleActions(option.actions.onOptionClosed ?? []);

    }, [option]);

    return isReady ? children : null;
}
