import React, {useCallback, useEffect, useState} from 'react';
import { useAppStore } from '../../../../../utils/useAppStore';
import { actions } from '../../../../../reducers/AppContext';
import { MonogramPreviewImage } from './components/MonogramPreviewImage/MonogramPreviewImage';
import { FSEDropDown } from '../../../../FSEDropDown/FSEDropDown';
import { findProductOptionBySku } from '../../../../../reducers/appReducer';

const STYLES = ['Embossed', 'Gold', 'Silver'];
const CHARS = ['a','b','c','d','e','f','g','h','i','j','k','l','m','n','o','p','q','r','s','t','u','v','w','x','y','z','0','1','2','3','4','5','6','7','8','9'];
const styleOptions = STYLES.map(style => ({value: style.toLowerCase(), label: style}));
const initialOptions = CHARS.map(char => ({value: char.toLowerCase(), label: char.toUpperCase()}));

/**
 * Returns an object containing the current lining, initials, and
 * style settings.
 */
const getInitialMonogramValues = (options, valuesByPartId) => {
  const getValueByOptionSku = (sku) => {
    const {option_id} = findProductOptionBySku(options, sku) || {option_id: ''};
    const {value} = valuesByPartId[option_id] || {value: ''};
    return value;
  }

  // Retreve the option IDs by the known SKU so that they can be located
  // within the store and their values can be referenced.
  return {
    liningSKU: getValueByOptionSku('i1'),
    monogramStyle: getValueByOptionSku('ms'),
    monogramInitials: getValueByOptionSku('mo'),
  };
}


const AddButton = ({disabled = false, price = 0, onClick}) => {
  const label = price > 0 ? `$${price / 100} Add Monogram` : 'Add Monogram';
  const styles = !disabled
    ? "text-black border-black hover:bg-black hover:text-white"
    : "text-gray-300 bg-white border-gray-300"

  return <button className={`block px-4 py-3 lg:min-w-[100px] border-2 text-sm  ${styles} rounded-md cursor-pointer transition-colors`} {...{onClick}}>{ label }</button>;
}

export const MonogramForm = ({option}) => {
  const {state, actions} = useAppStore();

  // Get the default values of the Lining, Style and Initials.
  const [savedMonogramDetails, setSavedMonogramDetail] = useState(() => getInitialMonogramValues(state.options, state.valuesByPartId));
  const {liningSKU, monogramStyle, monogramInitials} = savedMonogramDetails;

  // Describe state based on stored monogram initials and style values.
  const [firstInitial, setFirstInitial] = useState((monogramInitials[0] || '').toLowerCase());
  const [middleInitial, setMiddleInitial] = useState((monogramInitials[1] || '').toLowerCase());
  const [lastInitial, setLastInitial] = useState((monogramInitials[2] || '').toLowerCase());
  const [style, setStyle] = useState(monogramStyle);
  const curInitials = `${firstInitial}${middleInitial}${lastInitial}`;
  const isComplete = curInitials.length > 0 && style !== '';
  const isSaved = monogramStyle !== '' && monogramInitials !== '';

  const updateStoredMonogramValues = (style, initials) => {
    actions.setOptionValue('ms', style);
    actions.setOptionValue('mo', initials ? initials.toUpperCase() : null);
    setSavedMonogramDetail({liningSKU, monogramStyle: style || '', monogramInitials: initials || ''});
  }

  const addMonogram = useCallback(() => {
    if (isComplete) {
      updateStoredMonogramValues(style, curInitials)
    }
  }, [style, curInitials, isComplete]);

  const clearMonogram = useCallback((e) => {
    e.preventDefault();
    setFirstInitial('');
    setMiddleInitial('');
    setLastInitial('');
    setStyle('');
    updateStoredMonogramValues(null, null)
  }, [style, curInitials]);

  useEffect(() => {
    if (isComplete && isSaved) {
      updateStoredMonogramValues(style, curInitials)
    }
  }, [isComplete, isSaved, curInitials, style]);

  const monogramPrice = state.product.hide_prices ? 0 : option.premium_surcharge;

  return (
    <div className="px-4 py-4">
      <p className="text-sm">{ option.help_text }</p>

      <div className="flex flex-row-reverse justify-between mt-4">
        <div className="w-1/5">
          <MonogramPreviewImage {...{liningSKU}} style={style}/>
        </div>
        <div className="flex-0 w-3/5 mr-3">
          <div className="">
            <label className="text-sm font-bold" htmlFor="style">Style</label>
            <FSEDropDown onSelect={setStyle} fieldId="style" options={styleOptions} value={style} />
          </div>

          <div className="mt-4">
              <label htmlFor="initial">Initials</label>
              <div className="flex">
                <FSEDropDown onSelect={setFirstInitial} fieldId='initial1' options={initialOptions} value={firstInitial} defaultLabel="" />
                <span className="block mr-1"></span>
                <FSEDropDown onSelect={setMiddleInitial} fieldId='initial2' options={initialOptions} value={middleInitial} defaultLabel="" />
                <span className="block mr-1"></span>
                <FSEDropDown onSelect={setLastInitial} fieldId='initial3' options={initialOptions} value={lastInitial} defaultLabel="" />
              </div>
          </div>

          <div className="mt-6">
            { !isSaved && <AddButton disabled={!isComplete} onClick={addMonogram} price={monogramPrice} />}
            { isSaved && <a className="text-link mt-sm d-block js-btn-remove-monogram" href="#" onClick={clearMonogram}>Remove</a>}
          </div>
        </div>
      </div>
    </div>
  );
};
