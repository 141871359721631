import React from 'react';

export const LuxeBadge = ({width, height}) => {

  const w = !width ? 182 * 0.5 : width;
  const h = !height ? 360 * 0.5 : height;
  const px = w * 1.1; // 40%
  const py = h * .4; // 10%

  return (
    <div className="text-center bg-black bg-opacity-75" style={{paddingLeft: px, paddingRight: px, paddingTop: py, paddingBottom: py}}>
      <div className="mx-auto">
        <svg width={`${w}px`} height={`${h}px`} viewBox="0 0 182 360" version="1.1">
            <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="LaudiVidni-Luxe-L" transform="translate(-53.000000, -36.000000)" fill="#FFFFFF">
                    <path d="M110.2955,250.6304 L110.2955,36.0004 L53.0615,36.0004 L53.0615,305.7764 L234.9385,305.7764 L234.9385,250.6304 L110.2955,250.6304 Z M68.2835,395.9994 L216.5525,395.9994 L216.5525,351.1544 L68.2835,351.1544 L68.2835,395.9994 Z" id="Fill-1"></path>
                </g>
            </g>
        </svg>
      </div>
    </div>
  );
};
