import React, { createContext, useContext, useEffect } from "react";
import mitt from "mitt"; // https://github.com/developit/mitt

const EmitterContext = createContext();

export function useEmitter() {
  return useContext(EmitterContext);
}

export function useEvent(event, handler) {
  const emitter = useEmitter();
  useEffect(() => {
    emitter.on(event, handler);
    return () => emitter.off(event, handler);
  }, [emitter, event, handler]);
  return [emitter];
}

export function EventChannel({ children }) {
  const emitter = mitt();
  return (
    <EmitterContext.Provider value={emitter}>
      {children}
    </EmitterContext.Provider>
  );
}