import { getUrlParameter } from './getUrlParameter';

export const getApiEndpoint = () => {
  return window.designTool.api;
}

export const getDesignerId = () => {
  return getUrlParameter('designer') ?? null;
}

export const loadProductManifest = (productId) => {

  const url = getApiEndpoint() + `/design/${productId}/getForm`;

  return fetch(url).then(response => {
    if (!response.ok) {
      throw new Error('Error connecting to service.');
    }

    return response.json();
  });
}

export const storeDesign = (productId, design, image) => {

  const url = getApiEndpoint() + '/designs';
  const designer_id = getDesignerId();

  return fetch(url, {
      method: 'POST',
      body: JSON.stringify({product: productId, design, image, designer_id}),
      headers: {
        'Content-Type': 'application/json'
      },
  })
  .then(response => {
    if (!response.ok) {
      throw new Error('Error saving design.');
    }

    return response.json();
  });
}