import React, { useContext, useEffect, useRef, useState } from 'react';
import { loadProductManifest } from '../../utils/api';
import { AppContext } from '../../reducers/AppContext';
import { load as loadProductPreview } from '../../utils/productViewLoader';
import { intro, outro } from '../../utils/animations/appLoadingScreenAnimation';

export const AppLoadingScreen = ({productId}) => {
  const { actions } = useContext(AppContext);
  const [message, setMessage] = useState(null);
  const screenEl = useRef();

  useEffect(() => {
    // DOM element for performing intro/outro animations.
    var screen = screenEl.current;
    var productJson = null;

    intro(screen)

      .then(() => loadProductManifest(productId))

      .then(async data => {
        productJson = data;
        setMessage(`Loading your ${data.title}`)
        await loadProductPreview(productId);
      })

      .then(() => outro(screen))

      .then(() => actions.setProduct(productJson))

      .catch((error) => {
        // This is a really weird way to make sure that any errors from
        // the promise chain make it up to React's ErrorBoundary logic.
        // https://github.com/facebook/react/issues/14981
        setMessage(() => { throw error });
      });

  }, []);

  return (
    <div ref={screenEl} className="relative flex flex-col w-screen h-screen justify-center items-center">
      <div className="background-fill absolute top-0 left-0 w-full h-full" style={{backgroundColor: '#EFEFEF'}} />
      <div className="progress_bar relative w-3/4 sm:w-1/4 opacity-0">
        <span className={`
          progress-bar__text
          mb-2
          block h-2 text-center
          opacity-${ message ? '100' : '0'}
          transition ease-in duration-500
          text-base
        `}>{message}</span>
        <span className="relative block h-1 bg-gray-400 rounded-sm mt-8 overflow-hidden">
          <span className="progress-bar__fill absolute top-0 left-0 w-0 h-1 bg-gray-700 rounded-r-sm"></span>
        </span>
      </div>
    </div>
  );
}
