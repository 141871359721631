import React, { useCallback, useState } from 'react';
import { useEmitter} from '../utils/events';
import { getUrlParameter } from '../utils/getUrlParameter';

const getCtas = () => {

    return (getUrlParameter('btnLabel') || 'Save Design')
        .split(',')
        .map((str) => ({label:str}));
}

export const DoneBtn = () => {
    const [ctas, setLabels] = useState(getCtas());
    const emitter = useEmitter();

    const clicked = useCallback((label) => {
        setLabels(ctas.map(cta => {
            if (cta.label === label) {
                cta.label = 'saving...';
            }
            cta.disabled = true;
            return cta;
        }));

        emitter.emit('done', label)
    });

    return (
        <div className="mx-auto flex gap-x-4 justify-center">
            { ctas.map(({label, disabled = false}, index) => {

                const btnClasses = {
                    'lg': 'menu-item--done-btn hidden lg:block opacity-0 px-8 py-3 bg-black border-2 border-black text-white uppercase cursor-pointer hover:bg-white hover:text-black hover:font-md transition-colors whitespace-no-wrap',
                    'sm': 'lg:hidden text-sm opacity-0 menu-item--done-btn px-4 py-2 bg-black text-white uppercase cursor-pointer',
                };

                if (disabled) {
                    btnClasses['lg'] = 'menu-item--done-btn hidden lg:block opacity-0 px-8 py-3 bg-black border-2 border-black text-white uppercase cursor-pointer transition-colors whitespace-no-wrap pointer-events-none';
                    btnClasses['sm'] = 'lg:hidden text-sm opacity-0 menu-item--done-btn px-4 py-2 bg-black text-white uppercase cursor-pointer pointer-events-none';
                }

                return (
                    <div key={index}>
                        <button className={btnClasses['lg']} onClick={(e) => clicked(label)}
                            disabled={disabled}
                        >{label}</button>
                        <div className="flex justify-center w-full">
                            <span className={btnClasses['sm']} onClick={(e) => clicked(label)}>{label}</span>
                        </div>
                    </div>
                );
            }) }
        </div>
    )
}