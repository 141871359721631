import React from 'react';
import ReactDOM from 'react-dom';
import Configurator from './Designer/index';
import { getUrlParameter } from './Designer/utils/getUrlParameter';
import { flare } from "@flareapp/flare-client";

// only launch in production, we don't want to waste your quota while you're developing.
if (process.env.NODE_ENV === 'production') {
  flare.light('yiqhDi83AIwbKrYJ8yWzVuNIJgRHS5RM');
}

var container = document.createElement('DIV');
container.id = 'designtool';
document.body.appendChild(container);

// TODO ensure that this code is only loaded in an iFrame!
// TODO ensure a productId has been provided

ReactDOM.render((
  <React.StrictMode>
    <div className="">
      <Configurator productId={getUrlParameter('p')} />
    </div>
  </React.StrictMode>
), document.getElementById('designtool'));
