import React from 'react';
import { useReducer } from 'react';
import { reducer, initialState } from './appReducer';

//-- ACTION CREATORS -----------------------------------------------//

export const actions = {
  setOptionValue: (optionId, value) => ({type: 'SET_OPTION_VALUE', optionId, value}),
  setProduct: (product) => ({type: 'SET_PRODUCT', product}),
  gotoStep: (step) => ({type: 'GOTO_STEP', step}),
}

//-- CONTEXT -------------------------------------------------------//

const AppContext = React.createContext();
const AppContextConsumer = AppContext.Consumer;

function AppContextProvider({children}) {
  // Stored state
  const [state, dispatch] = useReducer(reducer, initialState);
  const value = {state, actions: {
    setOptionValue: (optionId, value) => dispatch(actions.setOptionValue(optionId, value)),
    setProduct: (product) => dispatch(actions.setProduct(product)),
    gotoStep: (step) => dispatch(actions.gotoStep(step)),
  }};

  return (
    <AppContext.Provider {...{value}}>{children}</AppContext.Provider>
  );
}

export { AppContext, AppContextProvider, AppContextConsumer };

