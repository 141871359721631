import { storeDesign as apiStoreDesign } from './api';

/**
 * Returns the thumbnail for the current design.
 */
const getThumbnail = async () => {

  // TODO: figure out why mobile crashes when saving a larger res thumbnail.
  if (window.innerWidth < 800) {
    return window.designer.setCamera('Front').then(result => {
      return window.designer.getSnapshot(225, 250);
    });
  }

  return new Promise(async (resolve) => {
    // Reset the camera.
    await window.designer.setCamera('Front')
    // Wait for U3D to upate the view
    await new Promise(resolve => setTimeout(resolve, 500));
    // Generate the thumbnail using the designer lib.
    const thumbnail = await window.designer.getSnapshot(900, 1000);

    resolve(thumbnail);
  });
}

export const storeDesign = async (state, action = null) => {
  // product information
  const productSku = state.product.sku;
  const productPrice = state.totalPrice;
  const productThumb = await getThumbnail();
  const productDesign = {
    'price': productPrice,
    'options': state.valuesByPartId
  };

  // store the product...
  const result = await apiStoreDesign(productSku, productDesign, productThumb.data);

  const product = {
    price: productPrice,
    thumbnail: result.design.image,
    addToCart: result.add_to_cart,
    design: result.design,
  };

  // Post the results back to the parent frame.
  window.parent.postMessage({type: 'DONE', product, action}, '*');
}
