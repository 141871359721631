import { gsap } from 'gsap';

export const intro = (el) => {
  const progressBar = el.querySelector('.progress_bar');
  const progressBarFill = el.querySelector('.progress-bar__fill');
  const targetFill = 12 + Math.round(Math.random() * 33);
  gsap.to(progressBar, {duration: 1, delay: 0, ease: 'easein', opacity: 1, startAt: { opacity: 0 }});
  gsap.to(progressBarFill, {duration: 20, delay: 1.6, ease: 'none', width: `90%`});
  return gsap.to(progressBarFill, {duration: 0.6, delay: 1, ease: 'easein', width: `${targetFill}%`});
}

export const outro = (el) => {
  const progressBar = el.querySelector('.progress_bar');
  const progressBarFill = el.querySelector('.progress-bar__fill');

  gsap.to(progressBarFill, {duration: 1, delay: 0,  ease: 'easein', width: '100%', overwrite: true});
  return gsap.to(progressBar, {duration: .5, delay: 1, ease: 'easein', opacity: 0});
}
