import React, {useEffect, useRef, useState} from 'react';
import { useAppStore } from '../../../../../utils/useAppStore';
import { findProductOptionBySku } from '../../../../../reducers/appReducer';
import { ActionWrapper } from '../../ActionWrapper';

const AddButton = ({disabled = false, price = 0, onClick}) => {
  const label = price > 0 ? `Save Nameplate $${price / 100}` : 'Save Nameplate';
  const styles = !disabled
    ? "text-black border-black hover:bg-black hover:text-white"
    : "text-gray-300 bg-white border-gray-300"

  return <button className={`block px-4 py-3 lg:min-w-[100px] border-2 text-sm  ${styles} rounded-md cursor-pointer transition-colors`} {...{onClick}} {...{disabled}}>{ label }</button>;
}

const ToggleButton = ({label, onClick, disabled = false}) => {
  const styles = !disabled
    ? "text-black border-black hover:bg-black hover:text-white"
    : "text-gray-300 bg-white border-gray-300"

  return <button className={`block px-4 py-3 lg:min-w-[100px] border-2 text-sm  ${styles} rounded-md cursor-pointer transition-colors`} {...{onClick}}>{ label }</button>;
}

const getEngravingValue = (state) => {

  const getValueByOptionSku = (sku) => {
    const {option_id, default_value} = findProductOptionBySku(state.options, sku) || {option_id: ''};
    const {value} = state.valuesByPartId[option_id] || {value: default_value};
    return value;
  }

  return  JSON.parse(getValueByOptionSku('mnmplt'));
}

/**
 * Helper effect for manipulating the U3D Nameplate.
 */
const useU3DNameplateController = ({
  showNameplate = true,
  lineOne = null,
  lineTwo = null,
  defaultLineOne = 'Fido',
  defaultLineTwo = '999-999-9999', 
}) => {
  useEffect(() => {
    const text = !lineOne && !lineTwo ? defaultLineOne : lineOne || '';
    window.Unlimited3D.updateOverlay({overlay: 'engraving', overlayEntry: 'line1', options: {text}});
  }, [lineOne, lineTwo]);

  useEffect(() => {
    const text = !lineOne && !lineTwo ? defaultLineTwo : lineTwo || '';
    window.Unlimited3D.updateOverlay({overlay: 'engraving', overlayEntry: 'line2', options: {text}});
  }, [lineOne, lineTwo]);

  useEffect(() => {
    if (showNameplate) {
      window.Unlimited3D.showParts({partObjects: [{parts:['collar_laudividni_final:nameplate']}]});
    } else {
      window.Unlimited3D.hideParts({parts:['collar_laudividni_final:nameplate']});
    }
  }, [showNameplate]);
  
  return {
    clear: () => {
      window.Unlimited3D.updateOverlay({overlay: 'engraving', overlayEntry: 'line1', options: {text: defaultLineOne}});
      window.Unlimited3D.updateOverlay({overlay: 'engraving', overlayEntry: 'line2', options: {text: defaultLineTwo}});
    }
  }
}

const getCharactersRemaining = (text, maxChars) => {
  if (typeof maxChars !== 'number' || isNaN(maxChars)) {
    throw new Error('maxChars must be a number');
  }

  return maxChars - (typeof text === 'string' ? text.length : 0);
}

export const EngravingForm = ({option}) => {
  const {state, actions} = useAppStore();
  const price = state.product.hide_prices ? 0 : option.premium_surcharge;
  const [lines] = useState(getEngravingValue(state));
  const [nameplateSaved, setNameplateSaved] = useState(lines ? true : false);
  const nameplateSavedRef = useRef(nameplateSaved);
  const [lineOne, setLineOne] = useState(lines && lines.length >= 1 ? lines[0] : null);
  const [lineTwo, setLineTwo] = useState(lines && lines.length >= 2 ? lines[1] : null);

  const [showNameplate, setShowNameplate] = useState(window.lauDebugHideNameplate ? false : true);

  const {clear} = useU3DNameplateController({lineOne, lineTwo, showNameplate, nameplateSaved:nameplateSaved});

  const [optionWithActions] = useState({...option, actions: {
    onOptionOpened: [
      ['play_transition', 'nametag_camera'],
    ],
    onOptionClosed: [
      ['play_transition', 'nametag_default'],
    ],
  }});

  const clearNameplate = () => {
    setNameplateSaved(false);
    setLineOne(null);
    setLineTwo(null);
  };

  const toggleNameplate = () => {
    const newShowNameplate = !showNameplate;
    window.lauDebugHideNameplate = newShowNameplate ? false : true;
    if (!newShowNameplate) {
      setLineOne(null);
      setLineTwo(null);
    }

    setShowNameplate(newShowNameplate);
  }
  
  useEffect(() => {
    if (!nameplateSaved || (!lineOne && !lineTwo)) {
      actions.setOptionValue('mnmplt', null);
    } else {
      actions.setOptionValue('mnmplt', [lineOne, lineTwo]);
    }

    nameplateSavedRef.current = nameplateSaved;
  }, [nameplateSaved, lineOne, lineTwo]);

  useEffect(() => {
    // Cleanup function to clear unsaved nameplates when component unmounts.
    return () => {
      if (!nameplateSavedRef.current) clear();
    }
  }, []);

  const formCta = !nameplateSaved
    ? (<AddButton onClick={() => setNameplateSaved(true)} disabled={!lineOne && !lineTwo} price={price} />)
    : ( <a className="text-link mt-sm d-block js-btn-remove-monogram" href="#" onClick={() => clearNameplate()}>Clear</a>);

  const formWrapperClasses =`pt-4 pb-8 border-b-2 border-gray-300 ${showNameplate ? 'block' : 'hidden'}`;

  return (
    <ActionWrapper option={optionWithActions}>
        <p className="px-4 mt-4 text-sm">{ option.help_text }</p>
        {/* Form */}
        <div className={formWrapperClasses}>
          <div className="px-4">
            <div className="flex justify-between mt-4">
              <div className="flex-0 w-3/5 mr-3">
                {/* Input Line One */}
                <div className="relative">
                  <label className="text-sm font-bold" htmlFor="line-one">Line One</label>
                  <input type="text" id="line-one" className="inline-block appearance-none w-full border bg-white text-sm text-gray-700 border-black focus:border-black py-2 px-4 pr-8 leading-tight focus:outline-none focus:bg-white"
                    onChange={(e) => setLineOne(e.target.value.trim())}
                    value={lineOne || ''}
                    maxLength="10"
                    placeholder="Fido"
                    disabled={nameplateSaved}
                  />
                  <span className="absolute right-0 top-0 mt-8 mr-2 text-xs text-gray-500">{getCharactersRemaining(lineOne, 10)}</span>
                </div>
                {/* Input Line Two */}
                <div className="relative mt-2">
                  <label className="text-sm font-bold" htmlFor="line-two">Line Two</label>
                  <input type="text" id="line-two" className="inline-block appearance-none w-full border bg-white text-sm text-gray-700 border-black focus:border-black py-2 px-4 pr-8 leading-tight focus:outline-none focus:bg-white"
                    onChange={(e) => setLineTwo(e.target.value.trim())}
                    value={lineTwo || ''}
                    maxLength="12"
                    placeholder="999-999-9999"
                    disabled={nameplateSaved}
                  />
                  <span className="absolute right-0 top-0 mt-8 mr-2 text-xs text-gray-500">{getCharactersRemaining(lineTwo, 12)}</span>
                </div>
                {/* Submit/Clear */}
                <div className="mt-6">{formCta}</div>
              </div>
            </div>
          </div>
        </div>
        <div className="px-4 mt-6">
          <ToggleButton label={showNameplate ? 'Remove Nameplate' : 'Add Nameplate'} onClick={() => toggleNameplate()}/>
        </div>
    </ActionWrapper>
  );
};
