import React, { useContext } from 'react';
import { MonogramForm } from './MonogramForm/index';
import { StyleOptionForm } from '../../../StyleOptionForm';
import { AppContext } from '../../../../reducers/AppContext';
import { MaterialMenu } from '../../../MaterialMenu/MaterialMenu';
import { getThumbnailForOption } from '../..';
import { EngravingForm } from './EngravingForm/EngravingForm';

const formsByType = {
  material: MaterialMenu,
  monogram: MonogramForm,
  engraving: EngravingForm,
  style_option: StyleOptionForm,
};

export const OptionForm = () => {
  const { state, actions} = useContext(AppContext);
  const option = state.optionsById[state.curStep];

  if (!option) {
    return null;
  }

  const value = state.valuesByPartId[option.option_id];
  const FormComponent = formsByType[option.type];

  return (
    <div className="flex flex-col h-full w-full bg-white">
      <div className="flex-grow-0">
        <div className="flex px-4 py-4 items-center border-b-2 border-gray-300">
          <div className="flex-0 mr-4 w-12 h-12 bg-gray-300 overflow-y-scroll">
            {getThumbnailForOption(option, value)}
          </div>
          <div className="flex-grow">
            <span className="block mb-1 txt-sm font-bold">{option.name}</span>
            <span className="block text-base">{value ? value.name : `None`}</span>
          </div>
          <button className="flex-0 inline-block px-4 py-2 text-sm text-black cursor-pointer border-2 border-gray-300 hover:bg-black hover:border-black hover:text-white transition-colors rounded-[96px]" onClick={() => actions.gotoStep(null)}>Back</button>
        </div>
      </div>
      <div className="flex-grow overflow-y-scroll">
        <FormComponent {...{option, value}} />
      </div>
    </div>
  );
};
