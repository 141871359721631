import React from 'react';
import { MaterialMenuItem } from './MaterialMenuItem';
import { MaterialPreview } from './MaterialPreview';

export const MaterialMenu = ({option, value}) => {
  return (
    <div className="py-4 h-full">
      <MaterialPreview />

      <div className="thumbnail-list">
        {option.values.map((m, i) => (
          <MaterialMenuItem key={i} material={m} option={option} isActive={value && m.sku === value.value} />
        ))}
      </div>
      <div className="absolute bottom-0 left-0 w-full h-12 bg-gradient-to-t from-white to-transparent"></div>
    </div>
  );
};
