let container = null;

export const getDesignerApi = () => {
  if (!window.designer) {
    throw new Error('LaudiVidni Designer API not loaded.');
  }

  return window.designer;
}

export const getU3dApi = () => {
  if (!window.Unlimited3D) {
    throw new Error('LaudiVidni Designer 3D API not loaded.');
  }

  return window.Unlimited3D;
}

export const getElement = () => {
  return container;
}

export const load = (productId) => {
  return new Promise(resolve => {
    const div = container = document.createElement('DIV');
    div.id = `designer-product-preview-${productId}`;
    div.style.width = '1036px';
    div.style.height = '536px';
    div.style.position = 'absolute';
    div.style.left = '-9999px';
    div.style.top = 0;
    div.style.backgroundColor = 'red';
    document.body.appendChild(div);

    // Use the Laudi Vidni designer API to load the product preview into
    // an offscreen div. U3D's library requires that the containing div
    // be attached to the DOM to propertly work.
    getDesignerApi().load(productId, div.id).then(() => {
      div.removeAttribute('style');
      div.style.width = '1036px';
      div.style.height = '536px';
      document.body.removeChild(div);

      // Force the BG color to match the design tool.
      // Note: the LAU designer doesn't provide an api for this, so we
      // go directly to the underlying Unlimited3D API.
      getU3dApi().changeBackgroundColor({
        viewport: "Viewer", color: "#EFEFEF", opacity: 1,
      });

      // HACK: (2/23/22) U3D changed their editor to provide a default name of
      // "Viewport Publish" instead of "Viewer". Because we have solutions
      // that use both, and manually updating all names in the editor is
      // not currently possible, we trigger a second call to the new
      // default name. Either call fails silently if the provided viewport
      // does not exist.
      getU3dApi().changeBackgroundColor({
        viewport: "Viewport Publish", color: "#EFEFEF", opacity: 1,
      });

      resolve(container);
    })
  });
}
