import React from 'react';
import {useEffect, useMemo, useState} from 'react';
import {useAppStore} from '../../utils/useAppStore';
import {FSEDropDown} from '../FSEDropDown/FSEDropDown';

export const StyleOptionForm = ({option}) => {
  const {state, actions} = useAppStore();

  const [curValue, setCurValue] = useState(
    state.valuesByPartId[option.option_id].value || option.default_value
  );

  const options = useMemo(() => (
    option.values.map(v => ({
      label: `${v.title}`, value: v.sku}))
  ), [option]);

  useEffect(() => {
    actions.setOptionValue(option.option_id, curValue);
  }, [curValue])

  return (
    <div style={{padding: '15px 20px 30px 20px', overflow: 'scroll'}}>
      <div>
        <p className="mb-sm text-body">{option.help_text}</p>
      </div>
      <div style={{display: 'flex', flexDirection: 'row', }}>
        <div className="form" style={{flex: '1 1 60%'}}>
          <div className="form-field">
            <FSEDropDown onSelect={setCurValue} fieldId={option.component_id} options={options} value={curValue} />
          </div>
        </div>
      </div>
    </div>
  )
}
