import React, { useContext, useRef, useEffect } from 'react';
import { ProductForm } from './components/ProductForm';
import { ProductPreview } from './components/ProductPreview';
import { AppContext, AppContextProvider } from './reducers/AppContext';
import { OptionForm } from './components/ProductForm/components/OptionForm';
import { SlideOut } from './components/SlideOut';
import { AppLoadingScreen } from './components/AppLoadingScreen';
import { EventChannel, useEvent } from './utils/events';
import { animate as intro } from './utils/animations/appScreenIntro';
import { AppErrorScreenBoundary } from './components/AppErrorScreen';
import { AppNav } from './components/AppNav';
import { storeDesign } from './utils/storeDesign';

import './styles/app.scss';

const App = ({productId}) => {
  const { state } = useContext(AppContext);
  const {product, totalPrice} = state;
  const { actions } = useContext(AppContext);
  const doneRef = useRef(false);

  const screen = useRef();

  useEvent('change-material', ({optionSku, materialSku}) => {
    window.designer.changeMaterial(optionSku, materialSku);
  });

  useEvent('done', (action = null) => {
    if (!doneRef.current) {
      doneRef.current = true;
      storeDesign(state, action);
    }
  });

  useEffect(() => {
    if (product) {

      // Set default things!
      // actions.setOptionValue(option.option_id, material.id);

      for(let i = 0; i < product.initial_selections.length; i++) {
        const selection = product.initial_selections[i];
        actions.setOptionValue(selection.sku, selection.value);
      };


      intro({screen: screen.current});
    }
  }, [product, screen]);

  if (!productId) {
    return <p>No product specified.</p>
  }

  if (!product) {
    return <AppLoadingScreen productId={productId} />;
  }

  return (
    <div ref={screen} className="relative w-screen h-screen overflow-hidden" style={{backgroundColor: '#EFEFEF'}}>
      <div className="relative z-10 w-full h-full lg:w-8/12 overflow-hidden">

        <div className="hidden lg:block absolute top-0 left-0 mt-4 ml-4">
          <span className='block text-xl uppercase'>{product.title}</span>
          { !state.product.hide_prices &&
            <span className="block text-base">Designed at ${totalPrice / 100}</span>
          }
        </div>

        <div id="app-product-preview-container" className="flex flex-col items-center justify-center w-full h-full hidden">
          <ProductPreview {...{product}} />
        </div>

        <div id="app-nav-container" className='absolute z-10 top-0 mt-6 px-4 w-full lg:mt-0 lg:px-0 lg:top-auto lg:bottom-0 lg:mb-12 lg:items-center'>
          <AppNav />
        </div>
      </div>

      <div key="product-form-container" id="app-product-form-container" className={`${state.curStep ? 'focus-blurred' : ''}`}>
        <ProductForm />
      </div>

      <SlideOut contentId={state.curStep}>
        {state.curStep && <OptionForm />}
      </SlideOut>
    </div>
  );
};

export default ({productId}) => {
  return (
    <AppErrorScreenBoundary>
      <AppContextProvider>
        <EventChannel>
          <App productId={productId} />
        </EventChannel>
      </AppContextProvider>
    </AppErrorScreenBoundary>
  );
};
