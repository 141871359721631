import React from 'react';
import { useCallback } from 'react';
import { reportReactError } from "@flareapp/flare-react";

export const AppErrorScreen = ({message}) => {

  const closeApp = useCallback(() => {
    window.parent.postMessage({type: 'CLOSE'}, '*');
  });

  return (
    <div className='flex flex-col justify-center items-center w-screen h-screen' style={{backgroundColor: '#EFEFEF'}}>
      <div className='flex flex-col items-center justify-center w-1/4 shadow-2xl bg-white px-5 pt-6 pb-4'>
        <h1 className='mb-4'>Oops! Something went wrong.</h1>
        <p className='px-6 py-4 rounded-sm text-xs text-gray-600 border border-gray-400 bg-color-gray-600 overflow-y-scroll'>{message}</p>
        <span className='block mt-4 px-8 py-2 bg-gray-300 text-black uppercase cursor-pointer' onClick={closeApp}>Ok</span>
      </div>
    </div>
  );
}

export class AppErrorScreenBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, message: '' };
  }

  componentDidMount() {
    window.addEventListener("unhandledrejection", (event) => {
      if (event.reason && event.reason.message) {
        this.setState({hasError: true, message: event.reason.message});
      }

      return true; // supress the error alert;
    });
  }

  componentDidCatch(error, info) {
    reportReactError(error, info);
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true, message: error.message };
  }

  render() {
    return this.state.hasError ? <AppErrorScreen message={this.state.message} /> : this.props.children;
  }
};