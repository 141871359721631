import React, { useRef, useContext, useState } from 'react';
import { LuxeBadge } from './LuxeBadge';
import { useEvent } from '../../utils/events';
import { AppContext } from '../../reducers/AppContext';

export const MaterialPreview = () => {
  const {state} = useContext(AppContext);
  const {product} = state;
  const [material, setMaterial] = useState(null);
  const showLuxeBadge = material && material.price > 0 && !product.hide_prices;
  const element = useRef();

  useEvent('material_menu__preview-material', material => {
    setMaterial(material);
  });

  const previewGalleryImage = material && (
    <div className="w-full h-full bg-cover" style={{ backgroundColor: '#ccc', backgroundImage: `url(${material.image})` }}>
      { showLuxeBadge && (
        <div className="flex flex-col items-center justify-center absolute w-full h-full">
          <LuxeBadge width={15} height={32} />
        </div>
      )}
    </div>
  );

  const previewGalleryDesc = material && material.description && (
    <>
      <p className="text-xs leading-snug">{material.description}</p>
      { showLuxeBadge && (
        <p className="mt-4 text-xs italic">+${material.price / 100} for Luxe Leather</p>
      )}
    </>
  );

  return material && (
    <div ref={element} className='bg-white shadow-lg absolute left-0 z-50' style={{width: 400, transform: 'translateX(calc(-100% - 20px))'}}>
      <div className="preview-image__gallery relative overflow-y-hidden h-48">
        {previewGalleryImage}
      </div>
      {previewGalleryDesc && (
        <div className="px-6 py-6">
          {previewGalleryDesc}
        </div>
      )}
    </div>
  );
}