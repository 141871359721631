import React, { useEffect, useState, useRef } from 'react';
import { gsap } from "gsap";


const transitionIn = (element) => {
  setTimeout(() => element.classList.add('is-open'), 100);
  return new Promise(resolve => setTimeout(resolve, 500));
  // return gsap.to(element, {x: '0', duration: 0.6, ease: 'power4.out'});
}

const transitionOut = (element) => {
  element.classList.remove('is-open');
  return new Promise(resolve => setTimeout(resolve, 500));
  // return gsap.to(element, {x: '100%', duration: 0.6, ease: 'power4.out'});
}

export const SlideOut = ({contentId, children}) => {
  const containerRef = useRef();

  const [contentById] = useState([]);
  const [curContentId, setCurContentId] = useState(contentId);
  const [curContent, setCurContent] = useState(children);

  const gotoContent = (contentId) => {
    setCurContentId(contentId);
    setCurContent(!contentId ? null : contentById[contentId]);
  }

  contentById[contentId] = children;

  useEffect(() => {
    if (curContentId) transitionIn(containerRef.current);
  }, [curContentId]);

  useEffect(() => {
    if (!curContentId && contentId) {
      gotoContent(contentId);
    } else if (contentId !== curContentId) {
      transitionOut(containerRef.current).then(() => gotoContent(contentId));
    } else {
      gotoContent(contentId);
    }
  }, [contentId]);

  return (
    <div ref={containerRef} className={`slide-out-container ${contentId ? 'is-open' : ''}`}>
      {contentId !== null && curContent}
    </div>
  );
}