import React from 'react';

export const getThumbForLining = (liningSku, monogramStyle) => {
  const thumbs = {
    // Black Spotted
    L08_embossed: 'https://cloud.laudividni.com/configurator/monogram-examples/black_embossed.jpg',
    L08_gold: 'https://cloud.laudividni.com/configurator/monogram-examples/black_gold.jpg',
    L08_silver: 'https://cloud.laudividni.com/configurator/monogram-examples/black_silver.jpg',

    // Brown Spotted
    L09_embossed: 'https://cloud.laudividni.com/configurator/monogram-examples/brown_embossed.jpg',
    L09_gold: 'https://cloud.laudividni.com/configurator/monogram-examples/brown_gold.jpg',
    L09_silver: 'https://cloud.laudividni.com/configurator/monogram-examples/brown_silver.jpg',

    // Pumpkin
    L13_embossed: 'https://cloud.laudividni.com/configurator/monogram-examples/pumpkin_embossed.jpg',
    L13_gold: 'https://cloud.laudividni.com/configurator/monogram-examples/pumpkin_gold.jpg',
    L13_silver: 'https://cloud.laudividni.com/configurator/monogram-examples/pumpkin_silver.jpg',

    // Black
    L14_embossed: 'https://cloud.laudividni.com/configurator/monogram-examples/black_embossed.jpg',
    L14_gold: 'https://cloud.laudividni.com/configurator/monogram-examples/black_gold.jpg',
    L14_silver: 'https://cloud.laudividni.com/configurator/monogram-examples/black_silver.jpg',

    // Medium Gray
    L15_embossed: 'https://cloud.laudividni.com/configurator/monogram-examples/gray_embossed.jpg',
    L15_gold: 'https://cloud.laudividni.com/configurator/monogram-examples/gray_gold.jpg',
    L15_silver: 'https://cloud.laudividni.com/configurator/monogram-examples/gray_silver.jpg',

    // Red
    L16_embossed: 'https://cloud.laudividni.com/configurator/monogram-examples/red_embossed.jpg',
    L16_gold: 'https://cloud.laudividni.com/configurator/monogram-examples/red_gold.jpg',
    L16_silver: 'https://cloud.laudividni.com/configurator/monogram-examples/red_silver.jpg',

    // Pink
    L17_embossed: 'https://cloud.laudividni.com/configurator/monogram-examples/pink_embossed.jpg',
    L17_gold: 'https://cloud.laudividni.com/configurator/monogram-examples/pink_gold.jpg',
    L17_silver: 'https://cloud.laudividni.com/configurator/monogram-examples/pink_silver.jpg',

    // Navy
    L18_embossed: 'https://cloud.laudividni.com/configurator/monogram-examples/navy_embossed.jpg',
    L18_gold: 'https://cloud.laudividni.com/configurator/monogram-examples/navy_gold.jpg',
    L18_silver: 'https://cloud.laudividni.com/configurator/monogram-examples/navy_silver.jpg',

    // Medium Taupe
    L19_embossed: 'https://cloud.laudividni.com/configurator/monogram-examples/taupe_embossed.jpg',
    L19_gold: 'https://cloud.laudividni.com/configurator/monogram-examples/taupe_gold.jpg',
    L19_silver: 'https://cloud.laudividni.com/configurator/monogram-examples/taupe_silver.jpg',

    // Cadet Blue
    L20_embossed: 'https://cloud.laudividni.com/configurator/monogram-examples/cadet_blue_embossed.jpg',
    L20_gold: 'https://cloud.laudividni.com/configurator/monogram-examples/cadet_blue_gold.jpg',
    L20_silver: 'https://cloud.laudividni.com/configurator/monogram-examples/cadet_blue_silver.jpg',

    // Plum
    L21_embossed: 'https://cloud.laudividni.com/configurator/monogram-examples/plum_embossed.jpg',
    L21_gold: 'https://cloud.laudividni.com/configurator/monogram-examples/plum_gold.jpg',
    L21_silver: 'https://cloud.laudividni.com/configurator/monogram-examples/plum_silver.jpg',
  };

  if (!monogramStyle) {
      return 'https://cloud.laudividni.com/configurator/monogram-examples/none.jpg';
  }

  let lining = !liningSku ? 'L14' : liningSku;

  const liningAndStyle = `${lining.toUpperCase()}_${monogramStyle.toLowerCase()}`;
  return thumbs[liningAndStyle] ? thumbs[liningAndStyle] : '';
};

export const MonogramPreviewImage = ({liningSKU, style}) => {
  const src = getThumbForLining(liningSKU, style);

  return (
    <div className="shadow border border-white">
      <img className="js-preview-image" src={src} style={{display: src ? 'block' : 'none'}}/>
    </div>
  );
}
