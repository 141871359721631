import React from 'react';
import { useContext } from 'react';
import { AppContext } from '../../reducers/AppContext';
import { useCallback } from 'react';

export const getThumbnailForOption = (option, value) => {
  if (value && value.image) {
    return <img className="w-12 h-12 group-hover:scale-105 transition-transform" src={value ? value.image : ''} />;
  } else if (option.type === 'monogram') {
    return <img className="w-12 h-12 group-hover:scale-105 transition-transform" src="https://cloud.laudividni.com/assets/materials/misc/monogram-icon.jpg" />;
  } else {
    return null;
  }
}

export const getValueStringForOption = (option, value) => {
  switch (option.sku) {
    case 'mnmplt':
        return value ? value.name : 'Add Engraving';
    default:
      return value ? value.name : `None`;
  }
}

export const ProductFormItem = ({optionId}) => {
  const {state, actions} = useContext(AppContext);
  const option = state.optionsById[optionId];
  const value = state.valuesByPartId[optionId];

  const clicked = useCallback(() => {
    actions.gotoStep(optionId);

    if (option.name.includes('Lining') && window.designer.getCameras().includes('Interior')) {
      window.designer.setCamera('Interior');
    }
  }, [optionId, actions]);

  return (
    <div className="flex min-w-[180px] lg:min-w-0 lg:w-full pl-2 pr-4 pt-2 pb-2 items-center cursor-pointer border-2 border-gray-300 group hover:border-black transition-colors rounded-sm" onClick={clicked}>
      <div className="flex-0 mr-2 lg:mr-4 w-12 h-12 bg-gray-300">
        {getThumbnailForOption(option, value)}
      </div>
      <div className="flex-grow pr-4 whitespace-no-wrap lg:pr-0 lg:whitespace-normal">
        <span className="block mb-1 txt-sm font-bold">{option.name}</span>
        <span className="hidden text-base lg:block">{getValueStringForOption(option, value)}</span>
        <div className="text-xs lg:hidden">Edit</div>
      </div>
      <div className="hidden flex-0 lg:block">Edit</div>
    </div>
  );
}

export const ProductForm = () => {
  const {state} = useContext(AppContext);
  const {product, options, totalPrice} = state;

  const filteredOptions = options.filter(o => o.sku !== 'ms');
  const leatherOptions = filteredOptions.filter(o => o.material_type.includes('leather'));
  const otherOptions = filteredOptions.filter(o => !o.material_type.includes('leather'));

  return (
    <div className="relative flex flex-col w-full h-full bg-white px-4 py-2 lg:py-4">
      <div className="lg:hidden flex-0 mt-4 mb-4">
        <span className='block mb-1 text-xl uppercase'>{product.title}</span>
        { !state.product.hide_prices &&
          <span className="block">Designed at ${totalPrice / 100}</span>
        }
      </div>

      <h1 className='hidden lg:block text-xl mb-4'>Customize</h1>

      <div className="flex flex-row lg:flex-col flex-1 pb-8 lg:pb-16 gap-x-2 gap-y-2 h-full overflow-y-scroll">
        <span className="hidden lg:block mb-1 txt-sm font-bold">Leathers</span>
        { leatherOptions.map((option, i) =>
          <ProductFormItem key={i} optionId={option.option_id} stepValueLabel="TODO" stepValueImage="TODO" />
        )}
        <span className="hidden lg:block mb-1 txt-sm font-bold mt-6">Other Details</span>
        { otherOptions.map((option, i) =>
          <ProductFormItem key={i} optionId={option.option_id} stepValueLabel="TODO" stepValueImage="TODO" />
        )}
      </div>
    </div>
  );
};
