import { gsap } from 'gsap';

const createViewMenuIntro = (screen) => {
  const items = screen.querySelectorAll('.menu-item--view-btn');
  const doneBtn = screen.querySelectorAll('.menu-item--done-btn');
  const ani = gsap.timeline();

  for(let i = 0; i < items.length; i++) {
    ani.to(items[i], {
      ease: 'ease-out', delay: i * 0.2, duration: 0.8,
      translateY: 0, opacity: .75,
      startAt: {translateY: '20px', opacity: 0}
    }, 0);
  }

  ani.to(doneBtn, {
    ease: 'ease-out', duration: 0.9, delay: 0,
    opacity: 1,
    startAt: {opacity: 0}
  }, items.length * 0.2);

  return ani;
}

export const animate = ({screen}) => {
  const form = screen.querySelector('#app-product-form-container');
  const logo = screen.querySelector('#app-logo');
  const productPreview = screen.querySelector('#app-product-preview-container');
  const isMobile = window.innerWidth <= 1023;

  const productIntro = {
    ease: 'none', duration: 1, delay: 0,
    opacity: 1,
    startAt: { opacity: 0 },
    onStart:() => { productPreview.classList.remove('hidden') }
  };

  const formIntroDesktop = {
    ease: 'power2.out', duration: 0.8, delay: 0,
    translateX: 0,
    startAt: { translateX: '100%', opacity: 1 },
  };

  const formIntroMobile = {
    ease: 'power2.out', duration: 0.8, delay: 0,
    translateY: 0,
    startAt: { translateY: '100%', opacity: 1 },
  };

  return new Promise(resolve => {
    const ani = gsap.timeline({ onComplete:resolve });
    ani.to(productPreview, productIntro, 1);
    ani.add(createViewMenuIntro(screen), 1.5);
    ani.to(form, (isMobile ? formIntroMobile : formIntroDesktop), 2.4);
  });
}
