import React, { useContext } from 'react';
import { AppContext } from '../../reducers/AppContext';
import { DoneBtn } from '../DoneBtn';

const ViewMenu = () => {
  const { state } = useContext(AppContext);
  const { product } = state;
  const views = product.view.camera_views.map((camera) => camera.label);

  const changeView = (view) => {
    window.designer.setCamera(view);
  };

  return views.length <= 1 ? null : (
    <div className="flex flex-row justify-between mx-auto w-full max-w-md mt-5 lg:mt-10">
      {views.map((view, i) =>
          <button key={i} className='menu-item--view-btn block px-2 py-1 lg:min-w-[80px] text-sm text-black cursor-pointer border-2 border-gray-400 hover:border-black hover:text-black transition-colors rounded-[96px] opacity-0' onClick={() => changeView(view)}>{view}</button>
      )}
    </div>
  );
}

export const AppNav = () => {
    return (
      <div className="text-center">
        <DoneBtn />
        <ViewMenu />
      </div>
    )
  }
