import React, { useCallback } from 'react';

export const FSEDropDown = ({fieldId, options = [], onSelect = null, value = '', defaultLabel="Select"}) => {
  const selected = useCallback((e) => {
    const value = e.currentTarget.value;
    if (onSelect) onSelect(value);
  });

  return (
    <div className="relative mt-2 max-w-xs">
      <select id={fieldId} className="inline-block appearance-none w-full border bg-white text-sm text-gray-700 border-black focus:border-black py-2 px-4 pr-8 leading-tight focus:outline-none focus:bg-white" {...{value}} onChange={selected}>
        <option value="" disabled>{defaultLabel}</option>
        {options.map(({value, label}, i) => <option key={i} value={value}>{label}</option>)}
      </select>
      <div className="absolute right-0 top-0 w-6 h-6 pointer-events-none" style={{top: '6px', right: '10px'}}>
        <svg viewBox="0 0 20 20" fill="currentColor">
          <path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd" />
        </svg>
      </div>
    </div>
  );
};
